import React from 'react'

const RiftBlog = () => {
  return (
    <div className='BlogContainer'>
      <p>RiftReport.gg is a League of Legends match history and stats tracker designed to provide detailed and accessible gameplay insights. It started as a revival of likeaboss.gg, with the goal of offering a clean and efficient way to analyze summoner profiles, match data, and performance trends. Using Riot Games' official API, RiftReport.gg delivers accurate, up-to-date information to help players track their progress and improve their gameplay.</p>
    </div>
  )
}

export default RiftBlog